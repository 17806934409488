import { query, queryRun } from "@fscrypto/domain";
import { Dashboard } from "@fscrypto/domain/dashboard";
import * as Events from "@fscrypto/domain/events";
import {
  Entity,
  EntityFactory,
  EventBus,
  OptionalStore,
  PersistentStore,
  Store,
  createOptionalStore,
  createPersistentStore,
  createStore,
  useEntity,
  useOptionalObservableValue,
  useOptionalStore,
} from "@fscrypto/state-management";
import { ImperativePanelHandle } from "@fscrypto/ui";
import { sortBy } from "lodash-es";
import { Observable, Subscription, combineLatest, filter, map } from "rxjs";
import invariant from "tiny-invariant";
import { formatAndReplace } from "~/shared/utils/format-and-replace-statement";
import * as eventBus from "~/state/events";
import { QueryClient, queryClient } from "../data/query-client";
import { QueryPoller } from "../data/query-poller";
import { useEffect } from "react";

export class Query implements Entity<query.Query> {
  public readonly id: string;
  store: PersistentStore<query.Query>;
  latestRun: OptionalStore<queryRun.QueryRunResult> = createOptionalStore();
  parameterRuns: Store<Record<string, Store<queryRun.QueryRunResult | undefined>>> = createStore({});
  #pollers: Map<string, QueryPoller> = new Map();
  #pollerSubscriptions: Map<string, Subscription> = new Map();
  panelRef: ImperativePanelHandle | null = null;
  constructor(
    private eventBus: EventBus<Events.QueryEvent | Events.WorkItemEvent>,
    private client: QueryClient,
    initialValue: query.Query,
    initialLatestRun?: queryRun.QueryRunResult,
  ) {
    this.id = initialValue.id;
    this.store = createPersistentStore(initialValue, (q) => this.#updatePersist(q), 500);
    if (initialLatestRun) {
      this.latestRun.set(initialLatestRun);
    }
    this.store.start();

    this.eventBus.events$.subscribe((e) => {
      switch (e.type) {
        case "QUERY.UPDATED.REALTIME":
          const storeUpdatedAt = this.store.get().updatedAt.getTime();
          const realtimeUpdatedAt = e.payload.timestamp;

          if (realtimeUpdatedAt > storeUpdatedAt) {
            this.get();
          }
          break;
        case "WORK_ITEM.UPDATED":
          if (e.payload.id === this.id) {
            this.store.set({ ...this.store.get(), name: e.payload.name }, false);
          }
      }
    });
  }

  async update(value: query.QueryUpdate) {
    this.store.set({ ...this.store.get()!, ...value }); // Optimistic update
    this.eventBus.debounceSend(Events.queries.updated(this.store.get()), 10);
  }

  async run() {
    // Set a placeholder run until we get the real results back
    this.latestRun.set(queryRun.createEmptyQueryRunResult(this.id));
    try {
      const result = await this.client.execute({
        queryId: this.id,
        executionType: "REALTIME",
        statement: this.store.get().statement,
      });
      this.latestRun.set(result);
      if (!result.queryRunId) {
        return;
      }
      this.#poll(result.queryRunId, (r) => {
        this.latestRun.set(r);
        if (r.status === "finished") {
          this.store.set({ ...this.store.get(), lastSuccessfulExecutionAt: r.endedAt as Date | null });
        }
      });
    } catch (e) {
      this.latestRun.set(queryRun.createErrorQueryRunResult(this.id, e as Error));
    }
  }

  async updateStatement(statement: string) {
    const newParams = sortBy(query.getUpdatedQueryParams(this.store.get().parameters, statement, this.id), "name");
    this.store.set({ ...this.store.get(), statement, parameters: newParams });
    this.eventBus.debounceSend(Events.queries.updated(this.store.get()), 10);
  }

  async updateName(name: string) {
    this.store.set({ ...this.store.get(), name });
    this.eventBus.debounceSend(Events.queries.updated(this.store.get()), 10);
  }

  async format() {
    const newStatement = formatAndReplace(this.store.get().statement);
    this.store.set({ ...this.store.get(), statement: newStatement });
  }

  async updateTTL(ttl: number) {
    this.store.set({ ...this.store.get(), ttlMinutes: ttl });
  }

  async cancel() {
    this.latestRun.get();
    const queryRunId = this.latestRun.get()?.queryRunId;
    invariant(queryRunId, "Can't cancel query without queryRunId");
    this.#pollers.get(queryRunId)?.cancel();
  }

  async fetchLatestSuccessfulResult() {
    const query = this.store.get();
    if (!query.lastSuccessfulCompassId) return;
    this.latestRun.set(queryRun.createLoadingQueryRunResult(this.id));
    const result = await this.client.fetchResults(query.lastSuccessfulCompassId);
    this.latestRun.set(result);
  }

  /**
   * Fetches the latest query results, including failed results and running results
   */
  async fetchLatestResult() {
    const query = this.store.get();
    if (!query.lastExecutedCompassId) return;
    // latest run is successful
    if (query.lastSuccessfulCompassId === query.lastExecutedCompassId) {
      await this.fetchLatestSuccessfulResult();
      return;
    }
    this.latestRun.set(queryRun.createLoadingQueryRunResult(this.id));
    const result = await this.client.fetchStatus(query.lastExecutedCompassId);
    if (!result.queryRunId) {
      this.latestRun.set(undefined);
      return;
    }
    this.latestRun.set(result);
    this.#poll(result.queryRunId, (r) => this.latestRun.set(r));
  }

  async get() {
    const updatedQuery = await this.client.get(this.id);
    this.store.set(updatedQuery, false);
  }

  async setVisibility(visibility: Dashboard["visibility"]) {
    this.store.set({ ...this.store.get(), visibility });
  }

  async updateParameter(parameter: query.QueryParameter) {
    const params = this.store.get().parameters;
    const updated = params.map((p) => (p.id === parameter.id ? parameter : p));
    this.store.set({ ...this.store.get(), parameters: updated });
  }

  async updateJSONEndpointAccess(hasJsonEndpointAccess: boolean) {
    this.store.set({ ...this.store.get(), hasJsonEndpointAccess });
  }

  async getQueriesWithJSONEndpoint(profileId: string) {
    const queries = await this.client.getQueriesWithFilter({ hasJsonEndpointAccess: true, profileId });
    return queries;
  }

  async runWithParameters(parameters: queryRun.QueryRunParams, hash: string, dashboardId?: string) {
    const paramStore = createStore<queryRun.QueryRunResult | undefined>(undefined);
    this.parameterRuns.set({ ...this.parameterRuns.get(), [hash]: paramStore });
    // create a placeholder run to show while we wait for the real results, this allows the loading state of the query run to appear early for percieved loading improvement
    paramStore.set(queryRun.createEmptyQueryRunResult(this.id));
    const result = await this.client.execute({
      queryId: this.id,
      executionType: "DASHBOARD_PARAMETERS",
      statement: this.store.get().statement,
      parameters,
      dashboardId,
    });
    // when the real results come back, update the param store
    paramStore.set(result);
    if (!result.queryRunId) {
      return;
    }

    this.#poll(result.queryRunId, (r) => paramStore.set(r));
  }

  async #poll(queryRunId: string, onResult: (result: queryRun.QueryRunResult) => void) {
    if (this.#pollerSubscriptions.get(queryRunId)) {
      const currentStatus = await this.#pollers.get(queryRunId)?.getCurrentStatus();
      if (currentStatus !== "finished") {
        return;
      }
      this.#pollerSubscriptions.get(queryRunId)?.unsubscribe();
      this.#pollers.delete(queryRunId);
      this.#pollerSubscriptions.delete(queryRunId);
    }
    const poller = new QueryPoller(queryRunId, this.client);
    const subscription = poller.results$.subscribe((r) => onResult(r));
    this.#pollers.set(queryRunId, poller);
    this.#pollerSubscriptions.set(queryRunId, subscription);
    poller.poll();
  }

  async #updatePersist(value: query.Query) {
    try {
      const res = await this.client.update(this.id, value);
      this.eventBus.send(Events.queries.updatedPersistSuccess(res));
      return res;
    } catch (e) {
      if (e instanceof Response) {
        console.error("Error updating query", e);
        this.eventBus.send(
          Events.queries.updatedPersistFailure({ error: e.statusText, statusCode: e.status, id: this.id }),
        );
      }
      throw e;
    }
  }
  async delete() {
    try {
      await this.client.delete(this.id);
      this.eventBus.send(Events.queries.deleted({ id: this.id }));
    } catch (error) {
      console.error("Error deleting query", error);
      Events.queries.deletedFailure({ id: this.id });
    }
  }

  setPanelRef(panel: ImperativePanelHandle) {
    this.panelRef = panel;
  }
  resizeIfClosed() {
    const size = this.panelRef?.getSize();
    if (!!size && size < 20) {
      this.panelRef?.resize(50);
    }
  }

  setData(query: query.Query, latestRun?: queryRun.QueryRunResult) {
    this.store.set(query, false);
    if (latestRun) {
      this.latestRun.set(latestRun);
    }
  }

  destroy() {
    this.store.stop();
    this.#pollers.forEach((poller) => poller.cancel());
    this.#pollerSubscriptions.forEach((sub) => sub.unsubscribe());
  }
}

export class QueryFactory implements EntityFactory<Query> {
  queries: Store<Record<string, Query>> = createStore({});
  constructor(
    private client: QueryClient,
    private eventBus: EventBus<Events.QueryEvent | Events.WorkItemEvent>,
  ) {
    this.eventBus.events$.subscribe((e) => {
      switch (e.type) {
        case "QUERY.DELETED":
          const q = this.queries.get();
          delete q[e.payload.id];
          this.queries.set(q);
          break;
        case "WORK_ITEM.REMOVED":
          if (e.payload.typename === "query") {
            const queries = this.queries.get();
            const query = queries[e.payload.id];
            if (!query) return;
            query.destroy();
            delete queries[e.payload.id];
            this.queries.set({ ...queries });
          }
          break;
      }
    });
  }

  async create(payload: query.QueryNew) {
    const query = await this.client.create(payload);
    const q = new Query(this.eventBus, this.client, query);
    this.queries.set({ ...this.queries.get(), [q.id]: q });
    this.eventBus.send(Events.queries.created(q.store.get()));
    return q;
  }

  async fork(queryId: string) {
    const query = await this.client.fork(queryId);
    const q = new Query(this.eventBus, this.client, query);
    this.queries.set({ ...this.queries.get(), [q.id]: q });
    this.eventBus.send(Events.queries.created(q.store.get()));
    return q;
  }

  from(query: query.Query, latestRun?: queryRun.QueryRunResult) {
    const existing = this.queries.get()[query.id];
    if (existing) {
      existing.setData(query, latestRun);
      return existing;
    }
    const q = new Query(this.eventBus, this.client, query, latestRun);
    this.queries.set({ ...this.queries.get(), [q.id]: q });
    return q;
  }

  async getById(queryId: string, resultType: "successful" | "any" | "none" = "any") {
    const cachedQuery = this.queries.get()[queryId];
    if (cachedQuery) return cachedQuery;
    const query = await this.client.get(queryId);
    const entity = new Query(this.eventBus, this.client, query);
    switch (resultType) {
      case "successful": {
        await entity.fetchLatestSuccessfulResult();
        break;
      }
      case "any": {
        await entity.fetchLatestResult();
        break;
      }
    }

    this.queries.set({ ...this.queries.get(), [entity.id]: entity });
    return entity;
  }

  async runMultiple(queryIds: string[]) {
    const queriesToRun = await Promise.all(queryIds.map((id) => this.getById(id)));
    await Promise.all(queriesToRun.map((query) => query.run()));
  }

  fromId(id: string) {
    return this.queries.get()[id];
  }
  from$(id: string) {
    return this.queries.value$.pipe(
      map((q) => q[id]),
      filter(Boolean),
    );
  }
  fromIds$(ids: string[]): Observable<Query[]> {
    return combineLatest(ids.map((id) => this.from$(id))).pipe(map((queries) => queries.filter(Boolean)));
  }

  fromSlugId$(slugId: string): Observable<Query> {
    return this.queries.value$.pipe(
      map((q) => Object.values(q).find((query) => query.store.get().slugId === slugId)),
      filter(Boolean),
    );
  }
}

export const queryFactory = new QueryFactory(queryClient, eventBus.v2.eventBus as EventBus<Events.QueryEvent>);

export const useOrFetchQuery = (id: string) => {
  const query = useQuery(id);
  useEffect(() => {
    if (query) return;
    queryFactory.getById(id);
  }, [id]);
  return query;
};

export const useQuery = (id: string) => {
  const entity = useEntity(queryFactory, id);
  const query = useOptionalStore(entity?.store);
  const latestRun = useOptionalStore(entity?.latestRun);
  const isSaving = useOptionalObservableValue(entity?.store.loading$);
  if (!entity || !query) {
    return null;
  }

  const isRunning = latestRun
    ? queryRun.isRunning(latestRun.status) || (queryRun.isFinished(latestRun.status) && !latestRun.resultsRetrieved)
    : false;

  const isCanceled = latestRun ? queryRun.isCanceled(latestRun.status) : false;
  return {
    entity,
    query,
    updateStatement: entity.updateStatement.bind(entity),
    updateName: entity.updateName.bind(entity),
    updateTTL: entity.updateTTL.bind(entity),
    updateParameter: entity.updateParameter.bind(entity),
    fetchQueriesWithJsonEndpoints: entity.getQueriesWithJSONEndpoint.bind(entity),
    updateJSONEndpointAccess: entity.updateJSONEndpointAccess.bind(entity),
    isSaving,
    isRunning,
    isCanceled,
    fork: () => queryFactory.fork(id),
    cancel: entity.cancel.bind(entity),
    format: entity.format.bind(entity),
    run: entity.run.bind(entity),
    setVisibility: entity.setVisibility.bind(entity),
    latestRun,
    delete: entity.delete.bind(entity),
    panelRef: entity.panelRef,
    setPanelRef: entity.setPanelRef.bind(entity),
    resizeIfClosed: entity.resizeIfClosed.bind(entity),
  };
};
